import { useState, useEffect, useCallback } from 'react';
import jwt, { JwtPayload } from 'jsonwebtoken';

type UserState = {
    accessToken?: string | null,
    orgId?: string | null,
}

export const useLoggedIn = (): [UserState, () => void] => {
    const [state, setState] = useState<UserState>({});

    const signOut = useCallback(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('orgId');
        setState({});
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        const orgId = localStorage.getItem('orgId');

        const decodedToken = jwt.decode(token as string) as JwtPayload;
        const currentTime = Math.floor(Date.now() / 1000); // convert milliseconds to seconds

        if (!!decodedToken?.exp && decodedToken.exp > currentTime) {
            setState({ accessToken: token, orgId });
        } else {
            signOut();
        }
    }, []);

    return [state, signOut];
};